<template>
    <section class="oc-materiales-proveedores container-fluid">
        <navbar-admin />
        <div class="row my-3 titulo-divisor">
            <div class="col-auto my-auto d-middle-center">
                <i class="icon-left-open cr-pointer" @click="$router.push({name: 'almacen.pedidos.internos.ordenes.compra'})" />
                <p class="f-600 f-14 ucfirst">Materiales de las orden de compra</p>
            </div>
            <div class="col my-auto pl-0">
                <hr class="my-0 bg-white" />
            </div>
            <div class="col-auto my-auto">
                <div class="row">
                    <div class="col-auto my-auto px-1 icon-option">
                        <el-tooltip content="Agregar materiales" visible-arrow effect="light">
                            <i class="icon-plus-circle  f-30 cr-pointer" @click="addMaterials"/>
                        </el-tooltip>
                    </div>
                    <div class="col auto my-auto">
                        <el-input v-model="buscarListado" placeholder="Buscar materiales" size="small" />
                    </div>
                </div>
            </div>
        </div>
        <!-- enviar orden compra -->
        <div class="row">
            <div class="col-6">
                <p class="f-600 text-general f-14">Proveedor: {{detalleOcs.proveedor.nombre}}</p>
            </div>
            <div class="col-6 text-lg-right">
                <button class="btn btn-general text-white f-12 px-3" @click="enviarOrdenCompra">Enviar orden de compra</button>
            </div>
        </div>
        <!-- tabla -->
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :usarPaginacion="false" :data="tableMaterials">
            <el-table-column label="Nombre material" prop="material" show-overflow-tooltip>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.material }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Categoría" prop="categoria" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.categoria }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Proyecto" prop="proyecto">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.proyecto }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Cantidad" prop="cantidad" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.cantidad }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor unitario" prop="valor_unitario" width="170">
                <template slot-scope="scope">
                    <CustomCurrencyInput 
                    v-model="scope.row.valor_unitario" 
                    @change="editUnitValue(scope.row)"
                    :options="{ currency: 'COP' }"
                    />
                </template>
            </el-table-column>
            <el-table-column width="80" align="center">
                <template slot-scope="scope">
                    <el-tooltip content="Eliminar" effect="light" visible-arrow>
                        <i class="icon-trash-can-outline f-20 icon-general-hover cr-pointer" @click="deleteMaterial(scope.row.id)" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials -->
        <modalAgregarMaterialesResponsable ref="openModalAgregarMateriales" @actilizarListaMateriales="actilizarListaMateriales" />
        <modal-enviar-orden-compra ref="openModalEnviarOc" />
        <modal-eliminar ref="openModalDeleteOc" title="Eliminar material de la OC" mensaje="¿Está seguro que quiere eliminar este material?" @delete="handleDeleteOC" />
    </section>
</template>

<script>
import modalAgregarMaterialesResponsable from './partials/modalAgregarMaterialesResponsable.vue'
import modalEnviarOrdenCompra from './partials/modalEnviarOrdenCompra.vue'
import { mapActions, mapGetters } from 'vuex'
import { Notification } from 'element-ui'
export default {
    name: 'agregarMaterialesProveedor',
    components: {
        modalAgregarMaterialesResponsable,
        modalEnviarOrdenCompra,
    },
    data() {
        return {
            buscarListado: '',
            tableMaterials:[],
            detalleOcs:{},
            idMaterial:''
        }
    },
    computed: {
        ...mapGetters({

        }),
    },
    created () {
        const detalleOcs = JSON.parse(localStorage.getItem('detalleOcs'))
        if (!detalleOcs) {
            new Notification({
                message:'No se encontraron materiales en la orden de compra',
                type:'error',
                dangerouslyUseHTMLString: false
            })
            this.$router.push({name:'almacen.pedidos.internos.ordenes.compra'})
            return
        }
        this.detalleOcs = detalleOcs
        this.tableMaterials = detalleOcs.materiales
    },
    mounted () {
        
    },
    methods: {
        ...mapActions({

        }),
        addMaterials(){
            this.$refs.openModalAgregarMateriales.toggle()
        },
        enviarOrdenCompra(){
            if (this.tableMaterials.length == 0) {
                new Notification({
                    message:'Orden de compra debe tener materiales',
                    type:'error',
                    dangerouslyUseHTMLString: false
                })
                return 0
            }
            this.$refs.openModalEnviarOc.toggle()
        },
        editUnitValue(val){
            this.tableMaterials.map(e => {
                if (e.id == val.id) e.valor_unitario = val.valor_unitario
            })
            this.detalleOcs.materiales = this.tableMaterials
            localStorage.setItem('detalleOcs', JSON.stringify(this.detalleOcs));
        },
        deleteMaterial(idMaterial){
            this.idMaterial = idMaterial
            this.$refs.openModalDeleteOc.toggle()
        },
        handleDeleteOC(){
            this.$refs.openModalDeleteOc.toggle()
            this.tableMaterials = this.tableMaterials.filter(e => e.id != this.idMaterial)
            this.detalleOcs.materiales = this.tableMaterials
            localStorage.setItem('detalleOcs', JSON.stringify(this.detalleOcs));
        },
        actilizarListaMateriales(){
            const detalleOcs = JSON.parse(localStorage.getItem('detalleOcs'))
            this.tableMaterials = detalleOcs.materiales
        }
    },
}
</script>

<style>

</style>